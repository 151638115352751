



import Vue from 'vue';
import ResetPasswordContainer from '@/container/auth/ResetPasswordContainer.vue';
export default Vue.extend({
  name: 'ResetPasswordScene',

  components: {
    ResetPasswordContainer,
  },
});
