














































































import Vue from 'vue';
import { resetPasswordModule } from '@/store/viewModule/auth/resetPasswordModule';
import { commonModule } from '@/store/viewModule/common/commonModule';

interface DataType {
  teamId: string;
  email: string;
  isValid: boolean;
}

export default Vue.extend({
  name: 'ResetPasswordContainer',

  data: (): DataType => {
    return {
      teamId: '',
      email: '',
      isValid: false,
    };
  },

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('validator.required'),
        email: (email) => {
          const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
          return emailRegExp.test(email) || this.$t('validator.email');
        },
      };
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    resetPassword() {
      commonModule.setLoader(true);
      resetPasswordModule.resetPassword({ email: this.email, teamId: this.teamId }).then((data) => {
        commonModule.setLoader(false);
        const response: any = data;
        if (response && response.email === this.email) {
          alert(this.$t('resetEmailSent'));
        } else if (data.error) {
          switch (data.error) {
            case 'E005':
              alert(this.$t('errorE005'));
              break;
            case 'E020':
              alert(this.$t('errorE020'));
              break;
            case 'E038':
              alert(this.$t('errorE038'));
              break;
            case 'E039':
              alert(this.$t('errorE039'));
              break;
            case 'E040':
              alert(this.$t('errorE040'));
              break;
            case 'E041':
              alert(this.$t('errorE041'));
              break;
            case 'E042':
              alert(this.$t('errorE042'));
              break;
            case 'E043':
              alert(this.$t('errorE043'));
              break;
            case 'E075':
              alert(this.$t('errorE075'));
              break;
            default:
              alert(this.$t('errorAuthAlert'));
          }
        }
      }).catch((error) => {
        commonModule.setLoader(false);
        alert(this.$t('errorUnexpected'));
      });
    },
  },
});
