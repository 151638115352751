import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { requestResetPassword } from '@/api/client';

export interface ResetPasswordState {}

@Module({ dynamic: true, store, name: 'reset-password', namespaced: true })
class ResetPasswordModule extends VuexModule implements ResetPasswordState {
  @Action
  public async resetPassword({ teamId, email }: { teamId: string, email: string }) {
    return await requestResetPassword({ teamId, email });
  }
}

export const resetPasswordModule = getModule(ResetPasswordModule);
